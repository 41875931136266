import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import { HashLink } from 'react-router-hash-link';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    // >
     
    //   <div className="container-sm">
    //     <div className={innerClasses}>
    //       <div className="hero-content">
    //         <h1 className="mt-0 mb-32 reveal-from-bottom" data-reveal-delay="700">
    //           <span className="text-color-secondary">The Aurora Project</span>

    //         </h1>
    //         <h7 className="mt-0 mb-32 reveal-from-bottom" data-reveal-delay="100">
    //           <span className="text-color-dark"></span>

    //         </h7>

    //         <a
>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h2 className="mt-0 mb-8 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">"Dimensions"</span> 
            </h2>
 
            <a
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={1200}
                height={1200} />
                
            </a>
          </div>
          {/* <div className="cta-box cta-box-inner  ">
              
              <ButtonGroup>
                <Button tag="a" color="light" wideMobile href="https://exchange.art/collections/The%20Aurora%20Project%20">
                  PRE-SALE OPEN
                </Button>
              </ButtonGroup>
            
            </div> */}

          {/* <div className="container-xs">
            <p className="m-0 mb-32 mt-32 reveal-from-bottom" data-reveal-delay="700"> 



              <span className="text-color-light-1y">The Aurora Project is a unique collection of colourful characters with vivid fantasy colours to brighten up the lives of those who hold them. 
            Every trait is hand drawn and every combination handpicked. 
            15% of sales and 50% of royalties donated to charity for Ehlers-Danlos support. Longterm holders get rewarded! 
            <p className="text-color-light-1y mt-32 has-top-divider">
                    For more on my personal story please check out  <HashLink smooth to="#showcase" >About me </HashLink>
                   </p>
              </span>
              
            </p>
          </div> */}


      

          </div>
        </div>

    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;