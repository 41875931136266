import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';
import Logo from './partials/Logo';


const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  return (
    <header
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>

          <nav className="header-nav header-social">
            <ul
              className="list-reset align-left"
            > 
              <li>
                <a href="https://discord.gg/qfRMGcqPcq" target="_blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg">
                    <title>Discord</title>
                    <path
                     d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                  </svg>
                </a>
              </li>
              
              <li>
                <a href="https://twitter.com/Fractured_rt" target="_blank">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg">
                    <title>Twitter</title>
                    <path
                      d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
                  </svg>
                </a>
              </li>
                            {/* <li>
                <a href="https://tiktok.com/@TheAuroraProject">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg">
                    <title>TikTok</title>
                    <path 
                    d="M 13.12,1.28
                    C 13.12,1.28 2.88,1.28 2.88,1.28
                      2.00,1.28 1.28,2.00 1.28,2.88
                      1.28,2.88 1.28,13.12 1.28,13.12
                      1.28,14.00 2.00,14.72 2.88,14.72
                      2.88,14.72 13.12,14.72 13.12,14.72
                      14.00,14.72 14.72,14.00 14.72,13.12
                      14.72,13.12 14.72,2.88 14.72,2.88
                      14.72,2.00 14.00,1.28 13.12,1.28 Z
                    M 11.84,7.14
                    C 11.77,7.15 11.70,7.15 11.62,7.15
                      10.78,7.15 10.04,6.72 9.62,6.07
                      9.62,7.78 9.62,9.73 9.62,9.76
                      9.62,11.27 8.39,12.49 6.89,12.49
                      5.38,12.49 4.16,11.27 4.16,9.76
                      4.16,8.26 5.38,7.03 6.89,7.03
                      6.94,7.03 7.00,7.04 7.06,7.04
                      7.06,7.04 7.06,8.39 7.06,8.39
                      7.00,8.38 6.94,8.37 6.89,8.37
                      6.12,8.37 5.49,8.99 5.49,9.76
                      5.49,10.53 6.12,11.16 6.89,11.16
                      7.66,11.16 8.34,10.55 8.34,9.78
                      8.34,9.75 8.35,3.51 8.35,3.51
                      8.35,3.51 9.63,3.51 9.63,3.51
                      9.75,4.66 10.68,5.57 11.84,5.65
                      11.84,5.65 11.84,7.14 11.84,7.14
                      11.84,7.14 11.84,7.14 11.84,7.14 Z" />
         </svg>
         
           
                </a>
              </li> */}
            </ul>
          </nav>

          {!hideNav &&
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                <div className="header-nav-inner">
                  <ul className={
                    classNames(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`
                    )}>
                    <li>
                      <HashLink smooth to="#showcase" onClick={closeMenu}>Previous Work</HashLink>
                    </li>
                    <li>
                      <HashLink smooth to="#roadmap" onClick={closeMenu}></HashLink>
                    </li>
                    <li>
                      <HashLink smooth to="#faq" onClick={closeMenu}></HashLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </>}
        </div>
        {/* <div className="header-logo">
     <h3 className="mt-32 mb-32 reveal-from-bottom" data-reveal-delay="700">
        <span className="text-color-primary">Fractured</span>
    </h3> */}
 
        <div className="header-logo"> 
          <Logo />
      </div>
      </div>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
