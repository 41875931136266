import React from 'react';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames( 
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <HashLink smooth to="#">
          <Image
            src={require('./../../../assets/images/logo.svg')}
            alt="Open"
            width={110}
            height={110} />
        </HashLink>
      </h1>
    </div>
  );
}

export default Logo;